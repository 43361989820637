import { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import StartIcon from "@mui/icons-material/Start";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";


function Sidenav({ color, brand, brandName, routes, roles, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const navigate = useNavigate();
  const [openReport, setOpenReport] = useState(false);
  
  const openSidenav = () => setMiniSidenav(dispatch, false);
  const isSidebarOpen = !miniSidenav;
  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const navigateToTabSection = (route, tabIndex) => {
    // Navigate to the report page with a query parameter for the tab index
    navigate(`${route}?tab=${tabIndex}`);
  };

  const handleReportClick = () => setOpenReport(!openReport);

  const renderRoutes = routes.map(({ type, name, icon, key, route, role, sectionid, tabIndex }) => {
    if (name === "Report" && role === "superadmin") {
      return (
        <div key={key}>
          <SidenavCollapse
            name={name}
            icon={icon}
            active={location.pathname.startsWith(route)}
            onClick={handleReportClick}
          />
          <Collapse in={openReport} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              <div
                style={{ textDecoration: "none", marginLeft: "5px", cursor: "pointer" }}
                onClick={() => navigateToTabSection(route, 0)}
              >
                <SidenavCollapse name="Billing Report" active={location.pathname === route && location.search.includes("tab=0")} />
              </div> 
              
              <div  
                style={{ textDecoration: "none", marginLeft: "5px", cursor: "pointer" }}
                onClick={() => navigateToTabSection(route, 1)}
              >
                <SidenavCollapse name="Task Report" active={location.pathname === route && location.search.includes("tab=1")} />
              </div>
             
              <div
                style={{ textDecoration: "none", marginLeft: "5px", cursor: "pointer" }}
                onClick={() => navigateToTabSection(route, 2)}
              >
                <SidenavCollapse name="Project Allocation" active={location.pathname === route && location.search.includes("tab=2")} />
              </div>

            </List>
          </Collapse>
        </div>
      );
    }

    if (type === "collapse" && (role === roles || role === "open")) {
      return (
        <NavLink key={key} to={route} style={{ textDecoration: "none" }}>
          <SidenavCollapse name={name} icon={icon} active={location.pathname === route} />
        </NavLink>
      );
    }

    if (type === "title") {
      return (
        <MDTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {name}
        </MDTypography>
      );
    }

    if (type === "divider") {
      return <Divider key={key} />;
    }

    return null;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox px={3} textAlign="center">
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2.5rem" marginRight="13px" />}
          {brandName && <MDBox component="img" src={brandName} alt="Brandname" width="8rem" />}
        </MDBox>
      </MDBox>
      <Divider />
      <List>{renderRoutes}</List>
      <Divider />
      <MDBox position="absolute" bottom={0} right={0} p={2}>
        <IconButton onClick={isSidebarOpen ? closeSidenav : openSidenav}>
          {isSidebarOpen ? <MenuOpenIcon /> : <StartIcon />}
        </IconButton>
      </MDBox>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  roles: PropTypes.string,
};

export default Sidenav;